@import "variables";

.gerant {
    margin-top: 120px;

    display: flex;
    justify-content: center;
    align-items: center;

    > :first-child {
        margin-right: 75px;
    }
}

.gerant_img {
    height: 222.63px;

    img {
        height: inherit;
        background: #c4c4c4;
        border-radius: 10px 30px 30px 30px;
    }
}

.gerant__description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    font-size: 18px;
    line-height: 34px;

    color: #1d293f;
}

@media screen and (max-width: 900px) {
    .gerant {
        align-items: flex-start;
    }
}

@media screen and (max-width: 800px) {
    .gerant {
        flex-direction: column;
    }

    .gerant_img {
        margin-bottom: 30px;
    }
}
